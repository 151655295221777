<template>
    <ASelect
        mode="multiple"
        v-model:value="valueState"
        placeholder="Pilih Customer"
        allow-clear
        show-search
        :not-found-content="state.loading ? undefined : 'Tidak ditemukan'"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (state.params.q = null)"
        @select="() => (state.params.q = null)"
        v-bind="$attrs"
        :loading="state.loading"
        :show-arrow="true"
        style="width:300px">
        <ASelectOption v-for="item in state.data" :key="item.id" :label="`${item.code} - ${item.name}`">
            <span v-html="highlight(`${item.code} - ${item.name}`)"></span>
        </ASelectOption>
        <template v-if="state.loading" #notFoundContent>
            <ASpin size="small" />
        </template>
    </ASelect>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, keyBy, merge, pickBy, values } from 'lodash'

/**
 * Docs endpoint
 * https://documenter.getpostman.com/view/20162021/2s8YsozvBw#8d681156-60ab-4ea7-8d85-424f1df1f5ff
 **/
export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
        distributor: {
            type: [String, Number, Array],
            default: null,
        },
        joinOptions: {
            type: [Array],
            default: () => [],
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const state = reactive({
            endpoint: `/api/filter/customer-mapping-vendor`,
            loading: false,
            data: [],
            params: {
                q: '',
                distributor: props.distributor,
            },
        })

        const fetchData = () => {
            state.loading = true
            apiClient.get(state.endpoint, { params: pickBy(state.params) })
                .then(({ data }) => {
                    const options = props.joinOptions.filter(item => item.id !== undefined)
                    if (options.length > 0) {
                        data = values(merge(keyBy(options, 'id'), keyBy(data, 'id')))
                    }
                
                    state.data = data
                })
                .finally(() => {
                    state.loading = false
                })
        }

        onMounted(() => {
            fetchData()
        })

        watch(() => props.distributor, () => {
            state.params.distributor = props.distributor
        })

        watch(state.params, debounce(() => {
            fetchData()
        }, 300))

        const onSearch = value => {
            state.params.q = value
        }

        const highlight = value => {
            return value.replace(new RegExp(state.params.q, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        return {
            state,
            valueState: useVModel(props, 'value'),
            highlight,
            onSearch,
        }
    },
}
</script>